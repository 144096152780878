import React, { Component } from 'react';
import MDSpinner from 'react-md-spinner';
import { isEmpty } from 'lodash';

import LoginModal from '../../booking/views/LoginModal';
import Notification from '../../common/Notification';
import CustomCheckbox from '../../common/CustomCheckbox';
import CustomRadioButton from '../../common/CustomRadioButton';
import TextFieldGroupLogin from '../../common/TextFieldGroupLogin';

import { connect } from 'react-redux';
import { userGetSingleProfile } from '../../../redux/actions/studentActions';
import { userGetSinglePackage } from '../../../redux/actions/packagesActions';
import {
  userGetAllCountries,
  userGetAllWorldCountries,
  userGetCountryDetails,
} from '../../../redux/actions/commonActions';
import {
  userCreateNewPayment,
  userGetPaymentMethodsList,
} from '../../../redux/actions/paymentActions';
import {
  validateInputPersonalInfo,
  validatePayment,
} from '../../common/validations/checkoutPayment';
import { DOMAIN } from '../../../utils/api';

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileScreen: false,
      isLoading_account: false,
      account_notification: {},
      isLoading_payment: false,
      payment_notification: {},
      isLoading_package: false,
      package_notification: {},
      isLoading_checkout: false,
      checkout_notification: {},
      selected_package: {},
      account_errors: {},
      payment_errors: {},
      isOpenLoginModal: false,
      country_options: [],
      city_options: [],
      // --- Customer Data --- //
      _id: '',
      email: '',
      first_name: '',
      last_name: '',
      // living_in_country: null,
      // living_in_city: null,
      living_in_city_text: '',
      street_name: '',
      house_number: '',
      postcode: '',
      phone: '',
      // --- Payment Method --- //
      selected_payment: '',
      payment_methods_list: [],
      terms_and_conditions_checked: false,
      subscribe_checked: false,
      paymentInProgress: false,
      checkout_url: '',
      // --- Checkout access permission --- //
      isLoadingCheckoutValidation: false,
      validToCheckout: false,
      online_trial_package: {},
      live_trial_package: {},
    };
  }

  componentWillMount = () => {
    const package_id = this.props.match.params.id;

    if (package_id && !isEmpty(package_id)) {
      this.getSinglePackage(package_id);
      // Get single student data
      if (this.props.auth.isAuthenticated) {
        this.getSingleStudent();
      }

      // Get payment methods list
      this.getPaymentMethodsList();
      this.getAllWorldCountries();
    } else {
      this.props.history.push('/packages');
    }
  };

  getSinglePackage = (package_id) => {
    this.setState({ isLoading_package: true, package_notification: {} });
    this.props
      .userGetSinglePackage({ package_id })
      .then((res) => {
        if (res.status !== 200) {
          this.setState({
            isLoading_package: false,
            package_notification: {
              text: 'Internal server error. Please try again later',
              isError: true,
            },
          });
          this.props.history.push('/packages');
        } else {
          this.setState({
            isLoading_package: false,
            selected_package: res.message.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading_package: false,
          package_notification: {
            text: 'We are experiencing technical difficulties while load your selected package data, please try again later.',
            isError: true,
          },
        });
      });
  };

  getSingleStudent = () => {
    this.setState({
      isLoading_account: true,
      account_notification: {},
      account_errors: {},
    });
    this.props
      .userGetSingleProfile({ email: this.props.auth.user.email })
      .then((res) => {
        if (res.status !== 200) {
          this.setState({
            isLoading_account: false,
            account_notification: {
              text: 'Internal server error. Please try again later',
              isError: true,
            },
          });
        } else {
          const {
            _id,
            email,
            first_name,
            last_name,
            living_in_country,
            living_in_city,
            living_in_city_text,
            street_name,
            house_number,
            postcode,
            phone,
          } = res.message.data;

          if (
            res.message.data.living_in_country &&
            !isEmpty(res.message.data.living_in_country)
          ) {
            this.props
              .userGetCountryDetails({
                country_code: res.message.data.living_in_country.value,
              })
              .then((res) => {
                if (res.status === 200) {
                  this.setState({
                    city_options: res.message.data[0].city_options,
                  });
                } else {
                  console.log('Failed to collect the city options.');
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            this.getAllWorldCountries();
          }

          this.setState({
            isLoading_account: false,
            _id,
            email,
            first_name,
            last_name,
            living_in_country,
            living_in_city,
            living_in_city_text,
            street_name,
            house_number,
            postcode,
            phone,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading_account: false,
          account_notification: {
            text: 'We are experiencing technical difficulties while load your account data, please try again later.',
            isError: true,
          },
        });
      });
  };

  getPaymentMethodsList = () => {
    this.setState({ isLoading_payment: true, payment_notification: {} });
    this.props
      .userGetPaymentMethodsList()
      .then((res) => {
        if (res.status !== 200) {
          this.setState({
            isLoading_payment: false,
            payment_notification: {
              text: 'Internal server error. Please try again later',
              isError: true,
            },
          });
        } else {
          this.setState({
            isLoading_payment: false,
            payment_methods_list: res.message.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading_payment: false,
          payment_notification: {
            text: 'We are experiencing technical difficulties while load payment methods, please try again later.',
            isError: true,
          },
        });
      });
  };

  screenSize = () => {
    this.setState({ isMobileScreen: window.innerWidth <= 900 });
  };

  componentDidMount = () => {
    window.addEventListener('screenSize', this.screenSize());
  };

  onChangeAccountDetails = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      account_errors: {
        ...this.state.account_errors,
        [e.target.name]: null,
      },
    });
  };

  openLoginModalHandler = () => {
    this.setState({ isOpenLoginModal: true });
  };

  closeLoginModalHandler = () => {
    this.setState({ isOpenLoginModal: false });
  };

  loadOptionsCountry = (inputValue, callback) => {
    this.props
      .userGetAllCountries({ query: inputValue })
      .then((response) => {
        callback(response.message.data);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  onChangeSelectCountry = (val) => {
    const { living_in_country, living_in_city, account_errors } = this.state;

    const selected_country = {
      value: val.value,
      label: val.label,
    };

    const city_options = val.city_options;

    this.setState({
      account_errors: {
        ...account_errors,
        living_in_country: null,
      },
      living_in_country: selected_country,
      living_in_city:
        living_in_country && living_in_country.value !== selected_country.value
          ? null
          : living_in_city,
      city_options,
    });
  };

  onChangeSelectedPayment = (method_id) => {
    this.setState({
      selected_payment: method_id,
      payment_errors: {
        ...this.state.payment_errors,
        selected_payment: null,
      },
      checkout_notification: {},
    });
  };

  onChangeRadioButton = (e) => {
    if (e.name === 'terms_and_conditions_checked') {
      this.setState({
        terms_and_conditions_checked: !this.state.terms_and_conditions_checked,
        payment_errors: {
          ...this.state.payment_errors,
          terms_and_conditions_checked: null,
        },
        checkout_notification: {},
      });
    } else {
      this.setState({
        subscribe_checked: !this.state.subscribe_checked,
      });
    }
  };

  isValidPersonalInfo = () => {
    const { errors, isValid } = validateInputPersonalInfo(this.state);
    if (!isValid) {
      this.setState({ account_errors: errors });
    }
    return isValid;
  };

  isValidPayment = () => {
    const { errors, isValid } = validatePayment(this.state);
    if (!isValid) {
      this.setState({ payment_errors: errors });
    }
    return isValid;
  };

  getAllWorldCountries = () => {
    this.props
      .userGetAllWorldCountries()
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            country_options: res.message.data,
          });
          return res.message.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onCheckout = () => {
    this.setState({
      isLoading_checkout: true,
      checkout_notification: {},
      account_notification: {},
    });
    // Check personal info form
    if (this.isValidPersonalInfo()) {
      // Check payment form
      if (this.isValidPayment() && !isEmpty(this.state.selected_package)) {
        const { title, sale_price } = this.state.selected_package;
        const {
          selected_payment,
          email,
          first_name,
          last_name,
          // living_in_country,
          // living_in_city,
          living_in_city_text,
          street_name,
          house_number,
          postcode,
          phone,
        } = this.state;

        const student_data = {
          email,
          first_name,
          last_name,
          display_name: `${first_name} ${last_name}`,
          // living_in_country,
          // living_in_city,
          living_in_city_text,
          street_name,
          house_number,
          postcode,
          phone,
        };

        if (!isEmpty(this.state._id)) {
          student_data['_id'] = this.state._id;
        }

        const dataToSubmit = {
          package_id: this.state.selected_package._id,
          package_title: title,
          package_price: sale_price,
          payment_method: selected_payment,
          student_data,
        };

        this.props
          .userCreateNewPayment(dataToSubmit)
          .then((res) => {
            if (res.status !== 200) {
              this.setState({
                isLoading_checkout: false,
                checkout_notification: {
                  text: res.message,
                  isError: true,
                },
              });
            } else {
              this.setState({
                isLoading: true,
                paymentInProgress: true,
                checkout_url: res.message.data.payment._links.checkout.href,
              });
              this.props.history.push(
                `/packages/payment/${res.message.data.payment.metadata.order_id}`,
                {
                  payment_method: res.message.data.payment.method,
                  package_title:
                    res.message.data.payment.metadata.package_title,
                  payment_status: res.message.data.payment.status,
                  checkout_url: res.message.data.payment._links.checkout.href,
                },
              );
              window.open(res.message.data.payment._links.checkout.href);
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              isLoading_checkout: false,
              checkout_notification: {
                text: 'Internal server error. Please try again later',
                isError: true,
              },
            });
          });
      } else {
        this.setState({
          isLoading_checkout: false,
          checkout_notification: {
            text: 'Please complete the payment form below',
            isError: true,
          },
        });
      }
    } else {
      this.setState({
        isLoading_checkout: false,
        account_notification: {
          text: 'Please complete the form below',
          isError: true,
        },
      });
    }
  };

  render() {
    const {
      isMobileScreen,
      isOpenLoginModal,
      // isCheckoutModalOpen,
      // --- Package Details --- //
      selected_package,
      isLoading_package,
      // --- Account Details --- //
      email,
      first_name,
      last_name,
      // living_in_country,
      // living_in_city,
      // country_options,
      // city_options,
      street_name,
      house_number,
      postcode,
      living_in_city_text,
      phone,
      isLoading_account,
      account_notification,
      account_errors,
      // --- Payment Methods --- //
      selected_payment,
      isLoading_payment,
      payment_notification,
      payment_methods_list,
      payment_errors,
      paymentInProgress,
      checkout_url,
      terms_and_conditions_checked,
      subscribe_checked,
      isLoading_checkout,
      checkout_notification,
    } = this.state;

    const { auth } = this.props;

    let render_benefit;
    if (!isEmpty(selected_package)) {
      render_benefit = selected_package.benefit_list.map((item, idx) => (
        <div
          key={`benefit-${idx + 1}`}
          className={
            isMobileScreen ? 'd-flex mb-2' : 'd-flex align-items-center mb-2'
          }>
          <i
            className={
              isMobileScreen
                ? 'fa fa-check-circle-o mr-2 mt-1'
                : 'fa fa-check-circle-o mr-2'
            }
            style={{ color: '#2acf7f' }}></i>
          <h6 className="fs-16 text-capitalize mb-0">{item.text}</h6>
        </div>
      ));
    } else {
      render_benefit = (
        <h6 className="fs-18 text-capitalize">No package Selected</h6>
      );
    }

    let render_payment_methods;
    if (!isEmpty(payment_methods_list)) {
      render_payment_methods = payment_methods_list.map((method, idx) => {
        return (
          <div
            key={`method-${idx + 1}`}
            style={{ width: '95%' }}
            className="pt-30 pb-20 border-bottom mx-auto">
            <label
              className={
                isMobileScreen
                  ? 'd-flex align-items-center px-20'
                  : 'd-flex align-items-center px-25'
              }>
              <CustomCheckbox
                error={
                  !isEmpty(payment_errors) &&
                  !isEmpty(payment_errors.selected_payment)
                }
                onChange={() => this.onChangeSelectedPayment(method.id)}
                className="mr-2"
                checked={selected_payment === method.id}
              />
              <h6 className="mb-0 fs-16 mr-3">{method.description}</h6>
              <img src={method.image.svg} alt={method.id} />
            </label>
          </div>
        );
      });
    } else {
      render_payment_methods = (
        <div className={isMobileScreen ? 'px-20' : 'px-40'}>
          <Notification notification={payment_notification} />
        </div>
      );
    }

    return (
      <div id="package-wrapper" className={isMobileScreen ? 'px-0' : 'px-0'}>
        <div className="row mx-auto">
          <div className="col-md-7">
            {/* PERSONAL INFORMATION CARD */}
            <div
              className={
                isMobileScreen
                  ? 'card card-body p-20'
                  : 'card card-body py-20 px-60'
              }>
              <h1 className="fs-25 mt-20 mb-0 text-black font-weight-bold">
                Personal Information
              </h1>
              {isLoading_account ? (
                <div className="d-flex justify-content-center flex-column my-40">
                  <div className="text-center">
                    <MDSpinner />
                  </div>
                  <div className="text-center mt-3">
                    <small>
                      Getting your personal information. Please wait...
                    </small>
                  </div>
                </div>
              ) : (
                <>
                  {auth.isAuthenticated ? null : (
                    <div className="d-flex align-items-center mt-2 mb-0">
                      <p className="mb-0 fs-16">Already have an account ?</p>
                      <button
                        onClick={this.openLoginModalHandler}
                        className="btn button-transparent border-0 px-2 letter-spacing-0 text-info fs-16">
                        Sign in here
                      </button>
                    </div>
                  )}
                  <div className="my-30">
                    <Notification notification={account_notification} />
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroupLogin
                          label="First name *"
                          field="first_name"
                          value={first_name}
                          placeholder="First name"
                          onChange={this.onChangeAccountDetails}
                          disabled={isLoading_account || isLoading_checkout}
                          error={account_errors.first_name}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroupLogin
                          label="Last name *"
                          field="last_name"
                          value={last_name}
                          placeholder="Last name"
                          onChange={this.onChangeAccountDetails}
                          disabled={isLoading_account || isLoading_checkout}
                          error={account_errors.last_name}
                        />
                      </div>
                    </div>
                    {/* <AsyncSelectFieldGroup
                      placeholder='Type to search'
                      label="Country"
                      value={living_in_country}
                      loadOptions={this.loadOptionsCountry}
                      onChange={this.onChangeSelectCountry}
                      error={account_errors.living_in_country}
                      isDisabled={isLoading_account || isLoading_checkout}
                      isClearable={false}
                    /> */}
                    {/* <SelectFieldGroup
                      label='Country of Origin'
                      placeholder='Select...'
                      value={living_in_country}
                      options={country_options}
                      onChange={this.onChangeSelectCountry}
                      error={account_errors.living_in_country}
                      isDisabled={isLoading_account}
                      isClearable={false}
                      isSearchable={true}
                    />
                    <SelectFieldGroup
                      label='City'
                      value={living_in_city}
                      options={city_options}
                      onChange={val => this.onChangeAccountDetails({target: {name: 'living_in_city', value: val}})}
                      error={account_errors.living_in_city}
                      isClearable={false}
                      isDisabled={isLoading_account || isEmpty(living_in_country)}
                      isSearchable={true}
                    /> */}
                    <TextFieldGroupLogin
                      label="Street Name"
                      field="street_name"
                      value={street_name}
                      placeholder="Street name"
                      onChange={this.onChangeAccountDetails}
                      disabled={isLoading_account || isLoading_checkout}
                      error={account_errors.street_name}
                    />
                    <TextFieldGroupLogin
                      label="House Number"
                      field="house_number"
                      value={house_number}
                      placeholder="House number"
                      onChange={this.onChangeAccountDetails}
                      disabled={isLoading_account || isLoading_checkout}
                      error={account_errors.house_number}
                    />
                    <TextFieldGroupLogin
                      label="City"
                      field="living_in_city_text"
                      value={living_in_city_text}
                      placeholder="City"
                      onChange={this.onChangeAccountDetails}
                      disabled={isLoading_account || isLoading_checkout}
                      error={account_errors.living_in_city_text}
                    />
                    <TextFieldGroupLogin
                      label="Postal code"
                      field="postcode"
                      value={postcode}
                      placeholder="Postal code"
                      onChange={this.onChangeAccountDetails}
                      disabled={isLoading_account || isLoading_checkout}
                      error={account_errors.postcode}
                    />
                    <TextFieldGroupLogin
                      label="Phone Number"
                      field="phone"
                      value={phone}
                      placeholder="Phone number"
                      onChange={this.onChangeAccountDetails}
                      disabled={isLoading_account || isLoading_checkout}
                      error={account_errors.phone}
                    />
                    <TextFieldGroupLogin
                      label="Email *"
                      field="email"
                      value={email}
                      placeholder="Email"
                      onChange={this.onChangeAccountDetails}
                      readOnly={this.props.auth.isAuthenticated}
                      disabled={isLoading_account || isLoading_checkout}
                      error={account_errors.email}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-md-5">
            {/* PACKAGE OVERVIEW CARD */}
            <div className="card card-body">
              <h1 className="fs-25 mt-10 mb-0 text-center text-black font-weight-bold">
                Your order
              </h1>
              <hr
                className="my-20 mx-auto border-top"
                style={{ width: '95%' }}
              />
              {isLoading_package ? (
                <div className="d-flex justify-content-center flex-column mx-auto my-40">
                  <div className="text-center">
                    <MDSpinner />
                  </div>
                  <div className="text-center mt-3">
                    <small>Getting package information. Please wait...</small>
                  </div>
                </div>
              ) : (
                <>
                  <div className={isMobileScreen ? 'px-10' : 'px-40'}>
                    <h6 className="fs-18 mb-20 text-capitalize">
                      {selected_package.title }
                    </h6>
                    <div className="mb-10">{render_benefit}</div>
                  </div>
                  <hr
                    className="my-20 mx-auto border-top"
                    style={{ width: '95%' }}
                  />
                  <div
                    className={
                      isMobileScreen
                        ? 'd-flex justify-content-between align-items-center px-10 mt-0 mb-20'
                        : 'd-flex justify-content-between align-items-center px-40 mt-0 mb-20'
                    }>
                    <h6 className="fs-16 mb-0">Price</h6>
                    <h6 className="fs-16 mb-0">
                      &euro; {selected_package.sale_price}
                    </h6>
                  </div>
                </>
              )}
            </div>
            {/* PAYMENT CARD */}
            <div className="card card-body pt-20">
              <h1 className="fs-25 mt-0 mb-0 text-center text-black font-weight-bold">
                Payment
              </h1>
              {isLoading_payment ? (
                <div className="d-flex justify-content-center flex-column my-40">
                  <div className="text-center">
                    <MDSpinner />
                  </div>
                  <div className="text-center mt-3">
                    <small className="fs-14">
                      Getting available payment methods. Please wait...
                    </small>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className={isMobileScreen ? 'px-20 mt-20' : 'px-30 mt-20'}>
                    <Notification notification={checkout_notification} />
                  </div>
                  <div
                    className={checkout_notification ? 'mt-0 mb-10' : 'my-10'}>
                    {render_payment_methods}
                  </div>
                  {/* Payment form validation error message */}
                  {!isEmpty(payment_errors) &&
                    !isEmpty(payment_errors.selected_payment) && (
                      <div
                        className="form-control-feedback mx-0"
                        style={{
                          padding: isMobileScreen ? '0 20px' : '0 36px',
                        }}>
                        {payment_errors.selected_payment}
                      </div>
                    )}
                  {isEmpty(payment_notification) ? (
                    <div
                      className="my-20"
                      style={{ padding: isMobileScreen ? '0 20px' : '0 36px' }}>
                      <div className="mb-20">
                        <label
                          className={
                            isMobileScreen
                              ? 'd-flex'
                              : 'd-flex align-items-center px-1'
                          }>
                          <CustomRadioButton
                            error={
                              !isEmpty(payment_errors) &&
                              !isEmpty(
                                payment_errors.terms_and_conditions_checked,
                              )
                            }
                            onChange={() =>
                              this.onChangeRadioButton({
                                name: 'terms_and_conditions_checked',
                              })
                            }
                            className={isMobileScreen ? 'mt-1 mr-2' : 'mr-2'}
                            checked={terms_and_conditions_checked}
                            disabled={isLoading_checkout}
                          />
                          <div className="d-flex align-items-center">
                            <p
                              className="mb-0 fs-16 letter-spacing-0"
                              style={{ lineHeight: '1.5' }}>
                              I have read and agree to the website
                              <a
                                href={`${DOMAIN}/terms-and-conditions`}
                                rel="noopener noreferrer"
                                target="_blank"
                                className="ml-1 text-black text-bold">
                                terms and conditions*
                              </a>
                            </p>
                          </div>
                        </label>
                        {/* Payment form validation error message */}
                        {!isEmpty(payment_errors) &&
                          !isEmpty(
                            payment_errors.terms_and_conditions_checked,
                          ) && (
                            <div className="form-control-feedback mx-0 pl-24">
                              {payment_errors.terms_and_conditions_checked}
                            </div>
                          )}
                      </div>
                      <div className="">
                        <label
                          className={
                            isMobileScreen
                              ? 'd-flex'
                              : 'd-flex align-items-center px-1'
                          }>
                          <CustomRadioButton
                            onChange={() =>
                              this.onChangeRadioButton({
                                name: 'subscribe_checked',
                              })
                            }
                            className={isMobileScreen ? 'mt-1 mr-2' : 'mr-2'}
                            checked={subscribe_checked}
                            disabled={isLoading_checkout}
                          />
                          <p
                            className="mb-0 fs-16 letter-spacing-0"
                            style={{ lineHeight: '1.5' }}>
                            Send me promos and product updates
                          </p>
                        </label>
                      </div>
                      <div className="form-group mt-40 mb-0">
                        {paymentInProgress && !isEmpty(checkout_url) ? (
                          <a
                            href={checkout_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-block p-10 btn-main-pink fs-18 fw-500">
                            Continue payment
                          </a>
                        ) : (
                          <button
                            onClick={this.onCheckout}
                            disabled={isLoading_checkout}
                            className="btn btn-block p-10 btn-main-pink fs-18 fw-500">
                            {isLoading_checkout ? <MDSpinner /> : 'Place order'}
                          </button>
                        )}
                      </div>
                    </div>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
        {isOpenLoginModal ? (
          <LoginModal
            isMobileScreen={isMobileScreen}
            closeModal={this.closeLoginModalHandler}
            getStudentData={this.getSingleStudent}
          />
        ) : null}
        {/* { isCheckoutModalOpen ?
          <UnderConstructionModal
            closeModal={() => this.setState({ isCheckoutModalOpen : false })}
            pageTitle='In progress integrating with Mollie payment'
          />
        : null } */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  userGetSingleProfile,
  userGetAllCountries,
  userGetAllWorldCountries,
  userGetCountryDetails,
  userGetSinglePackage,
  userGetPaymentMethodsList,
  userCreateNewPayment,
})(Checkout);
